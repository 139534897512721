
import Vue from 'vue';
import { CallRailAccount, CallRailCampaign } from '../../../../store/modules/callRail/types';
import { RefreshToSeeUpdatedDate } from '../constants';

export default Vue.extend({
  name: 'CallRailNew',

  props: ['editRights', 'advertiser'],

  data: (): {
    search: string;
    loading: {
      data: boolean;
      updating: boolean;
    };
    connections: CallRailAccount[];
    connectedCampaigns: CallRailCampaign[];
    expandedAccount: string;
    changed: boolean;
    RefreshToSeeUpdatedDate: string;
  } => ({
    search: '',
    loading: {
      data: false,
      updating: false,
    },
    connections: [],
    connectedCampaigns: [],
    expandedAccount: '',
    changed: false,
    RefreshToSeeUpdatedDate,
  }),

  created() {
    this.getAvailableConnections();
    this.getConnections();
  },

  computed: {
    selectedConnections(): CallRailAccount[] {
      return this.groupCampaignsByAccount(this.connectedCampaigns, this.connections);
    },
    enabled(): boolean {
      return this.connectedCampaigns.length > 0;
    },
    hasFullEdit(): boolean {
      return this.editRights?.hasFullEdit;
    },
    filteredConnections() {
      if (this.search.length < 3) return this.connections;
      return this.connections.map((connection: CallRailAccount) => {
        return {
          ...connection,
          campaigns: connection.campaigns.filter(c => c.name.toLowerCase().includes(this.search.toLowerCase())),
        };
      });
    },
  },

  methods: {
    groupCampaignsByAccount(campaigns: CallRailCampaign[], accounts: CallRailAccount[]): CallRailAccount[] {
      if (!campaigns.length && !accounts.length) {
        return [];
      }
      const grouped = new Map(accounts?.map(a => [a, []]));

      for (const campaign of campaigns) {
        const account = accounts.find(account => {
          const accountCampaign = account.campaigns.find(c => c.id === campaign.id);
          return accountCampaign !== undefined;
        });
        if (account) {
          const campaigns = grouped?.get(account) || [];
          campaigns.push(campaign);
          grouped.set(account, campaigns);
        }
      }

      const groupedCampaigns: CallRailAccount[] = [];

      for (const [account, campaigns] of grouped?.entries()) {
        groupedCampaigns.push({
          id: account?.id,
          name: account?.name,
          campaigns,
        });
      }

      return groupedCampaigns;
    },
    async getAvailableConnections() {
      this.loading.data = true;
      const campaigns = await this.$store.dispatch('callRail/getAdvertiserAvailableCampaigns', this.advertiser.id);

      this.connections = campaigns ?? [];
      this.loading.data = false;
    },
    async getConnections() {
      this.loading.data = true;
      const campaigns = await this.$store.dispatch('callRail/getAdvertiserCampaigns', this.advertiser.id);
      this.connectedCampaigns = campaigns ?? [];
      this.changed = false;
      this.loading.data = false;
    },
    disconnectCampaign(campaign: CallRailCampaign) {
      this.connectedCampaigns = this.connectedCampaigns.filter((c: CallRailCampaign) => c.id !== campaign.id);
      this.changed = true;
    },
    connectCampaign(campaign: CallRailCampaign) {
      this.connectedCampaigns.push(campaign);
      this.changed = true;
    },
    onToggleCampaign(campaign: CallRailCampaign) {
      const isConnected = this.isCampaignConnected(campaign);
      return isConnected ? this.disconnectCampaign(campaign) : this.connectCampaign(campaign);
    },
    isCampaignConnected(campaign: CallRailCampaign) {
      return this.connectedCampaigns.findIndex((c: CallRailCampaign) => c.id === campaign.id) !== -1;
    },
    async onSave() {
      this.loading.updating = true;
      await this.$store.dispatch('callRail/updateAdvertiserConnector', {
        advertiserId: this.advertiser.id,
        campaignIds: this.connectedCampaigns.map((c: CallRailCampaign) => c.id),
      });
      this.changed = false;
      this.loading.updating = false;
    },
  },
});
